import React from "react";

const Logo = ({size}) => {
    const fontSize = size || '36px';
    return (
        <>
            <img src="/logo952.png" style={{width: '65px', height: '65px'}} />
            {/* <i className="fa fa-fire" style={{fontSize: fontSize, color: '#f9b115'}}></i> */}
        </>
    )
}

export default Logo;