import firebase from "firebase/compat/app";
import 'firebase/compat/firestore';
import "firebase/compat/auth";
import "firebase/compat/functions";
import "firebase/compat/storage";
// import { getStorage } from "firebase/compat/storage";
import firebaseJson from '../../inc/firebase.json';
// import getFirestore from "firebase/firestore";
// import { initializeApp } from "firebase/app";

// const app = initializeApp(firebaseJson);
// const db = getFirestore(app);
const FirebaseAuth = firebase.initializeApp(firebaseJson.config);
const Firestore = FirebaseAuth.firestore();
const CloudFunctions = FirebaseAuth.functions();
// const Storage = getStorage(FirebaseAuth);

export {FirebaseAuth, Firestore, CloudFunctions}