import React, { useState, useRef, useEffect, useContext } from "react";
import Firebase from "firebase/compat/app";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import FirebaseUI from "../../../components/FirebaseUI";
import Logo from "../../../components/Logo";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import GlobalStyles from "@mui/material/GlobalStyles";
import Container from "@mui/material/Container";
import CameraIcon from "@mui/icons-material/PhotoCamera";
import Stack from "@mui/material/Stack";
import CardMedia from "@mui/material/CardMedia";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import StarIcon from "@mui/icons-material/Star";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import { Link as RouterLink } from "react-router-dom";
import Badge from "@mui/material/Badge";
import RepeatIcon from "@mui/icons-material/Repeat";
import StartIcon from "@mui/icons-material/Start";
import BusinessIcon from "@mui/icons-material/Business";
import DashboardCustomizeIcon from "@mui/icons-material/DashboardCustomize";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import SearchBox from "../Search/Search";


const cards = [];

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return null;
}

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="white"
      style={{ marginTop: "3%", textAlign: 'center', marginBottom: '3%' }}
      {...props}
    >
      {"Copyright © "}
      <a
        style={{ color: "white" }}
        href="https://hypernovadev.space"
        target="_blank"
      >
        Hypernova Technologies
      </a>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const data = [
  {
    name: "Hypertection",
    "Time to Deploy (Days)": 1,
    // "Cost to Deploy": 1
  },
  {
    name: "Builders",
    "Time to Deploy (Days)": 20,
    // "Cost to Deploy": 20
  },
  {
    name: "Dev Agency",
    "Time to Deploy (Days)": 31,
    // "Cost to Deploy": 32
  },
];

const qdata = [
  {
    name: "Hypertection",
    "Quality Scale": 8,
    // "Cost to Deploy": 1
  },
  {
    name: "Builders",
    "Quality Scale": 3,
    // "Cost to Deploy": 20
  },
  {
    name: "Dev Agency",
    "Quality Scale": 7,
    // "Cost to Deploy": 32
  },
];

const cdata = [
  {
    name: "Hypertection",
    "Cost Scale": 7,
    // "Cost to Deploy": 1
  },
  {
    name: "Builders",
    "Cost Scale": 4,
    // "Cost to Deploy": 20
  },
  {
    name: "Dev Agency",
    "Cost Scale": 10,
    // "Cost to Deploy": 32
  },
];

const SignIn = () => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [dataState, setDataState] = useState();
  const [booking, setBooking] = useState("Loading Prices");

  var data = [];
  const db = Firebase.firestore();
  // useEffect(() => {
  //   const data = [];
  //   db.collection("accounts")
  //     .get()
  //     .then((querySnapshot) => {
  //       querySnapshot.forEach((doc) => {
  //         // doc.data() is never undefined for query doc snapshots
  //         console.log(doc.id, " => ", doc.data());

  //         data.push(doc.data());
  //         // console.log("TESTING DATA", data);
  //       });
  //       setDataState(data);
  //     });
  //   console.log(data, "this is data");
  // }, [true]);
  // console.log(dataState, "datastate.name");

  // function search() {
  //   db.collection("accounts")
  //     .get()
  //     .then((querySnapshot) => {
  //       querySnapshot.forEach((doc) => {
  //         // doc.data() is never undefined for query doc snapshots

  //         data.push({ name: doc.data().name, booking: doc.data().booking });
  //       });
  //       setDataState(data);
  //     });
  //   // console.log(data, "data before setdata");
  // }

  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }


  const tiers = [
    {
      title: "60+",
      description: "Businesses Impacted",
    },
    {
      title: "70000+",
      description: "Dev Dollars Saved",
    },
    {
      title: "1000's",
      description: "Hours Saved",
    },
  ];

  const theme = createTheme();

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get("email"),
      password: data.get("password"),
    });
  };

  return (
    <ThemeProvider theme={theme}>
      {/* <Grid container component="main" sx={{ height: "100vh" }}> */}
      <ScrollToTopOnMount />
      <CssBaseline />
    
     

      <AppBar position="relative">
        <Toolbar style={{ backgroundColor: "black", color: "#d4aF37" }}>
          {/* <CameraIcon sx={{ mr: 2 }} /> */}
          <img src={"favicon.ico"} style={{ width: "26px", height: "26px" }} />
          <Typography
            variant="h6"
            color="inherit"
            style={{ marginLeft: "2px" }}
            noWrap
          >
            nlocked Sounds
          </Typography>
        </Toolbar>
      </AppBar>
      <main>
        {/* Hero unit */}
        <Box
          sx={{
            bgcolor: "black",
            pt: 8,
            pb: 6,
          }}
        >
          <Container maxWidth="sm">
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="#d4aF37" 
              gutterBottom
            >
              <strong>Unlocked Sounds</strong>
              {/* <Chip
                label="0.01"
                size="small"
                style={{ backgroundColor: "#012e45", color: "white" }}
              /> */}
            </Typography>

            <Typography
              variant="h5"
              align="center"
              color="#d4aF37"
              paragraph
            >
              Lightning-Fast Search Engine and Collaboration 🚀 
            </Typography>
            {/* The first technology framework to mitigate business, economic and
              global disasters for small-mid size enterprises. */}
            {/* <Stack
              sx={{ pt: 4 }}
              direction="row"
              spacing={2}
              justifyContent="center"
            >
              <Button
                variant="contained"
                href="#signin"
                style={{ backgroundColor: "#181a1d" }}
              >
                Sign- In (10 seconds)
              </Button>
              <Button
                variant="outlined"
                href="#difference"
                disabled
                style={{ color: "#181a1d", borderColor: "#181a1d" }}
              >
                More Coming Soon
              </Button>
              <FirebaseUI />
            </Stack> */}
            {/* <Stack
        direction="row"
        justifyContent="center"
        style={{marginTop: '5%'}}
        divider={<Divider orientation="vertical" flexItem />}
        spacing={2}
      >
        <Item>Item 1</Item>
        <Item>Item 2</Item>
        <Item>Item 3</Item>
      </Stack> */}
          </Container>
          {/* <Container
            maxWidth="md"
            component="main"
            style={{ marginTop: "15%" }}
          >
            <Paper style={{ backgroundColor: "#181a1d", color: "white" }}>
              <Grid
                container
                spacing={5}
                alignItems="flex-end"
                style={{ marginBotton: "3%" }}
              >
                {tiers.map((tier) => (
                  <Grid
                    item
                    style={{ marginBottom: "3%" }}
                    key={tier.title}
                    xs={12}
                    sm={tier.title === "Enterprise" ? 12 : 6}
                    md={4}
                  >
                    <div>
                      <div>
                        <Typography
                          variant="h2"
                          align="center"
                          color="white"
                          component="h2"
                        >
                          <strong>{tier.title}</strong>
                          <br />
                        </Typography>
                        <Typography
                          variant="h6"
                          align="center"
                          color="text.primary"
                          component="body"
                          style={{ backgroundColor: "#181a1d", color: "white" }}
                        >
                          {tier.description}
                        </Typography>
                      </div>
                    </div>
                  </Grid>
                ))}
              </Grid>
            </Paper>
          </Container> */}
          <Container style={{marginTop: '3%'}}>
          <SearchBox />
          {/* <TableContainer component={Paper} style={{marginTop: '6%'}}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table" style={{backgroundColor: 'black'}}>
          <TableHead>
            <TableRow>
              <TableCell align="center" style={{color: "white"}}>Name</TableCell>
              <TableCell align="center" style={{color: "white"}}>Birth City</TableCell>
              <TableCell align="center" style={{color: "white"}}>Price</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataState !== null && dataState !== undefined
              ? dataState.map((d) => {
                  return (
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="center" style={{color: "white"}}><RouterLink to={`/profile/${d?.accountId}`}>{d?.name}</RouterLink></TableCell>
                      <TableCell align="center" style={{color: "white"}}>{d?.bc}</TableCell>
                      <TableCell align="center" style={{color: "white"}}>{d?.booking}</TableCell>
                    </TableRow>
                  );
                })
              : "No Data Found"}
          </TableBody>
        </Table>
      </TableContainer> */}
          </Container>

        </Box>
      

          


       

         {/* <Container style={{ marginTop: "7%", color: '#d4aF37' }} id="difference">
          <Typography
            component="h1"
            variant="h2"
            align="center"
            color="#d4aF37"
            gutterBottom
          >
            <strong>Better Apps, Better Timing</strong>
   
          </Typography>
          <Typography gutterBottom component="p" variant="h6" align="center">
            We instantly deliver your favorite websites and business
            applications
            <br /> to help you fight the toughest, business disasters.
          </Typography>
          <Grid
            container
            spacing={5}
            alignItems="flex-end"
            style={{ marginTop: "7%", marginBottom: "3%" }}
          >
            <Grid item xs={12} sm={4}>
              <div>
               
                <div
                  style={{
                    color: "#d4aF37",
                    fontFamily: "Montserrat",
                    textAlign: "center",
                  }}
                >
                  <h3>
                    <strong>OUTSTANDING TIME SAVINGS</strong>
                  </h3>
                  <div>
                    <Typography
                      variant="p"
                      align="center"
                      color="#d4aF37"
                      paragraph
                    >
                      No legnthy time frames. With Hypertection, you get access to your applications when you pay for them. Skip the delivery time drama.
                    </Typography>
                  </div>
                </div>
              </div>
            </Grid>

            <Grid item xs={12} sm={4}>
              <div>
              
                <div
                  style={{
                    color: "#d4aF37",
                    fontFamily: "Montserrat",
                    textAlign: "center",
                  }}
                >
                  <h3>
                    <strong>OUTSTANDING QUALITY</strong>
                  </h3>
                  <div>
                    <Typography
                      variant="p"
                      align="center"
                      color="#d4aF37"
                      paragraph
                    >
                      ALL of our tools are engineered with modern
                      tech synonymous to industry giants (Meta,
                      Google, Netflix). NEVER worry about quality.{" "}
                    </Typography>
                  </div>
                </div>
              </div>
            </Grid>

            <Grid item xs={12} sm={4}>
              <div>
               
                <div
                  style={{
                    color: "#d4aF37",
                    fontFamily: "Montserrat",
                    textAlign: "center",
                  }}
                >
                  <h3>
                    <strong>SIMPLE COST</strong>
                  </h3>
                  <div>
                    <Typography
                      variant="p"
                      align="center"
                      color="#d4aF37"
                      paragraph
                    >
                      One monthly recurring cost. Say bye to hidden fees. Say bye to surprises. Say bye to upcharges. It's simple. {" "}
                    </Typography>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>  */}
        {/* <Card
          style={{
            marginTop: "12%",
            backgroundColor: "#181a1d",
            border: "1px solid",
          }}
          maxWidth="md"
        >
  
        </Card> */}
      
         {/* <Container style={{ marginTop: "12%", textAlign: 'center', color: '#d4aF37' }}>
           <Typography gutterBottom component="h1" variant="h2" align="center" style={{marginBottom: '6%'}}>
            <strong>Let's Get Started Today</strong>
            </Typography> 
            <Stack direction="row" alignItems="center" color="#d4aF37" justifyContent="center" marginBottom="2%"><StarIcon /><StarIcon /><StarIcon /><StarIcon /><StarIcon /></Stack>
            <Typography gutterBottom component="p" variant="h6" align="center" >
            <i>"As an early adopter of Hypertection, I now see the importance of automation in enterprise. <br />After a few weeks, we started seeing results we’ve never seen before..we saved time and energy on tasks we usually sat down and solved for hours."</i><br />
            - Mike J
          </Typography>
           <FirebaseUI /> 
        </Container>  */}

        
        <Container style={{ marginTop: "12%" }} id="signin">
          <Grid
            container
            spacing={5}
            alignItems="flex-end"
            style={{ marginTop: "12%", marginBottom: "3%" }}
          >
            <Grid item xs={12} sm={6}>
              <div>
                <Stack direction="row">
                <Typography
                  component="h2"
                  variant="h3"
                  color="#d4aF37" 
                  style={{ color: "white" }}
                  gutterBottom
                >
                  <strong>Unlocked Sounds </strong>
                  
                </Typography>
                <Chip
                label="Beta"
                size="small"
                style={{ backgroundColor: "white", color: "#d4aF37" }}
              />
</Stack>
               <Typography style={{marginBottom: '2%', color: "white"}}><strong>Here's What You Get As a Beta Member:</strong></Typography>
               <Typography style={{marginBottom: '2%', color: "white"}}> Reduced Costs to Access Premium Features</Typography>
               <Typography style={{marginBottom: '2%', color: "white"}}> Easy Access to Create Premium Profile</Typography>
               <Typography style={{marginBottom: '2%', color: "white"}}> Early Customer Perks</Typography>
              </div>
            </Grid>

            <Grid item xs={12} sm={6}>
              <div style={{textAlign: 'center'}}>
              <img src="/favicon.ico" style={{width: '50px', height: '50px'}} />
               <FirebaseUI />
              </div>
              <a onClick={handleClickOpen} style={{ textAlign: "center" }}>
                    <Typography
                      variant="body2"
                      style={{ textAlign: "center", marginTop: "2%", color: 'white' }}
                    >
                      By creating an account or signing in, you choose to
                      <br />
                      agree to the following <u>privacy policy.</u>
                    </Typography>
                  </a>
                 
            </Grid>
        
          </Grid>
        </Container>
      </main>
      <div style={{marginTop: '12%'}}>
      <Copyright sx={{ mt: 5 }} />
      </div>
      
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Privacy Policy
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom component="p" variant="p">
            Protecting your private information is our priority. This Statement
            of Privacy applies to hypertection.space, and Hypernova Technologies
            LLC and governs data collection and usage. For the purposes of this
            Privacy Policy, unless otherwise noted, all references to Hypernova
            Technologies LLC include hypertection.space, Hypernova Technologies,
            Hypertection, hypertection.space and hypernovadev.space. The
            Hypernova Technologies website is a News, Information, Technology
            Product site. By using the Hypernova Technologies website, you
            consent to the data practices described in this statement.
          </Typography>
          <Typography component="h6" variant="h6" gutterBottom>
            Collection of your Personal Information
          </Typography>
          <Typography gutterBottom component="p" variant="p">
            In order to better provide you with products and services offered,
            Hypernova Technologies may collect personally identifiable
            information, such as your:
            <br />
            <li>First and Last Name</li>
            <li>E-mail Address</li>
            <li>Phone Number (Not Required for Account Creation)</li>
            <br />
            If you purchase Hypernova Technologies's products and services, we
            collect billing and credit card information. This information is
            used to complete the purchase transaction. Please keep in mind that
            if you directly disclose personally identifiable information or
            personally sensitive data through Hypernova Technologies's public
            message boards, this information may be collected and used by
            others. We do not collect any personal information about you unless
            you voluntarily provide it to us. However, you may be required to
            provide certain personal information to us when you elect to use
            certain products or services. These may include: (a) registering for
            an account; (b) entering a sweepstakes or contest sponsored by us or
            one of our partners; (c) signing up for special offers from selected
            third parties; (d) sending us an email message; (e) submitting your
            credit card or other payment information when ordering and
            purchasing products and services. To wit, we will use your
            information for, but not limited to, communicating with you in
            relation to services and/or products you have requested from us. We
            also may gather additional personal or non-personal information in
            the future.
          </Typography>
          <Typography component="h6" variant="h6" gutterBottom>
            Use of your Personal Information
          </Typography>
          <Typography gutterBottom component="p" variant="p">
            Hypernova Technologies collects and uses your personal information
            to operate and deliver the services you have requested. Hypernova
            Technologies may also use your personally identifiable information
            to inform you of other products or services available from Hypernova
            Technologies and its affiliates.
          </Typography>
          <Typography component="h6" variant="h6" gutterBottom>
            Sharing Information with Third Parties
          </Typography>
          <Typography gutterBottom component="p" variant="p">
            Hypernova Technologies does not sell, rent or lease its customer
            lists to third parties. Hypernova Technologies may share data with
            trusted partners to help perform statistical analysis, send you
            email or postal mail, provide customer support, or arrange for
            deliveries. All such third parties are prohibited from using your
            personal information except to provide these services to Hypernova
            Technologies, and they are required to maintain the confidentiality
            of your information. Hypernova Technologies may disclose your
            personal information, without notice, if required to do so by law or
            in the good faith belief that such action is necessary to: (a)
            conform to the edicts of the law or comply with legal process served
            on Hypernova Technologies or the site; (b) protect and defend the
            rights or property of Hypernova Technologies; and/or (c) act under
            exigent circumstances to protect the personal safety of users of
            Hypernova Technologies, or the public.
          </Typography>
          <Typography component="h6" variant="h6" gutterBottom>
            Tracking User Behavior
          </Typography>
          <Typography gutterBottom component="p" variant="p">
            Hypernova Technologies may keep track of the websites and pages our
            users visit within Hypernova Technologies, in order to determine
            what Hypernova Technologies services are the most popular. This data
            is used to deliver customized content and advertising within
            Hypernova Technologies to customers whose behavior indicates that
            they are interested in a particular subject area.
          </Typography>
          <Typography component="h6" variant="h6" gutterBottom>
            Automatically Collected Information
          </Typography>
          <Typography gutterBottom component="p" variant="p">
            Information about your computer hardware and software may be
            automatically collected by Hypernova Technologies. This information
            can include: your IP address, browser type, domain names, access
            times and referring website addresses. This information is used for
            the operation of the service, to maintain quality of the service,
            and to provide general statistics regarding use of the Hypernova
            Technologies website.
          </Typography>
          <Typography component="h6" variant="h6" gutterBottom>
            Use of Cookies
          </Typography>
          <Typography gutterBottom component="p" variant="p">
            The Hypernova Technologies website may use "cookies" to help you
            personalize your online experience. A cookie is a text file that is
            placed on your hard disk by a web page server. Cookies cannot be
            used to run programs or deliver viruses to your computer. Cookies
            are uniquely assigned to you, and can only be read by a web server
            in the domain that issued the cookie to you. One of the primary
            purposes of cookies is to provide a convenience feature to save you
            time. The purpose of a cookie is to tell the Web server that you
            have returned to a specific page. For example, if you personalize
            Hypernova Technologies pages, or register with Hypernova
            Technologies site or services, a cookie helps Hypernova Technologies
            to recall your specific information on subsequent visits. This
            simplifies the process of recording your personal information, such
            as billing addresses, shipping addresses, and so on. When you return
            to the same Hypernova Technologies website, the information you
            previously provided can be retrieved, so you can easily use the
            Hypernova Technologies features that you customized. You have the
            ability to accept or decline cookies. Most Web browsers
            automatically accept cookies, but you can usually modify your
            browser setting to decline cookies if you prefer. If you choose to
            decline cookies, you may not be able to fully experience the
            interactive features of the Hypernova Technologies services or
            websites you visit.
          </Typography>
          <Typography component="h6" variant="h6" gutterBottom>
            Links
          </Typography>
          <Typography gutterBottom component="p" variant="p">
            This website contains links to other sites. Please be aware that we
            are not responsible for the content or privacy practices of such
            other sites. We encourage our users to be aware when they leave our
            site and to read the privacy statements of any other site that
            collects personally identifiable information.
          </Typography>
          <Typography component="h6" variant="h6" gutterBottom>
            Security of your Personal Information
          </Typography>
          <Typography gutterBottom component="p" variant="p">
            Hypernova Technologies secures your personal information from
            unauthorized access, use, or disclosure. Hypernova Technologies uses
            the following methods for this purpose:
            <br />
            <li>SSL Protocol</li>
            <br />
            When personal information (such as a credit card number) is
            transmitted to other websites, it is protected through the use of
            encryption, such as the Secure Sockets Layer (SSL) protocol. We
            strive to take appropriate security measures to protect against
            unauthorized access to or alteration of your personal information.
            Unfortunately, no data transmission over the Internet or any
            wireless network can be guaranteed to be 100% secure. As a result,
            while we strive to protect your personal information, you
            acknowledge that: (a) there are security and privacy limitations
            inherent to the Internet which are beyond our control; and (b)
            security, integrity, and privacy of any and all information and data
            exchanged between you and us through this Site cannot be guaranteed.
          </Typography>
          <Typography component="h6" variant="h6" gutterBottom>
            Right to Deletion
          </Typography>
          <Typography gutterBottom component="p" variant="p">
            Subject to certain exceptions set out below, on receipt of a
            verifiable request from you, we will:
            <br />
            <li>Delete your personal information from our records; and</li>
            <li>
              Direct any service providers to delete your personal information
              from their records.
            </li>
            Please note that we may not be able to comply with requests to
            delete your personal information if it is necessary to: <br />
            <li>
              Complete the transaction for which the personal information was
              collected, fulfill the terms of a written warranty or product
              recall conducted in accordance with federal law, provide a good or
              service requested by you, or reasonably anticipated within the
              context of our ongoing business relationship with you, or
              otherwise perform a contract between you and us;
            </li>
            <li>
              Detect security incidents, protect against malicious, deceptive,
              fraudulent, or illegal activity; or prosecute those responsible
              for that activity;
            </li>
            <li>
              Debug to identify and repair errors that impair existing intended
              functionality;
            </li>
            <li>
              Exercise free speech, ensure the right of another consumer to
              exercise his or her right of free speech, or exercise another
              right provided for by law;
            </li>
            <li>
              Comply with the California Electronic Communications Privacy Act;
            </li>
            <li>
              Engage in public or peer-reviewed scientific, historical, or
              statistical research in the public interest that adheres to all
              other applicable ethics and privacy laws, when our deletion of the
              information is likely to render impossible or seriously impair the
              achievement of such research, provided we have obtained your
              informed consent;
            </li>
            <li>
              Enable solely internal uses that are reasonably aligned with your
              expectations based on your relationship with us;
            </li>
            <li>Comply with an existing legal obligation; or</li>
            <li>
              {" "}
              Otherwise use your personal information, internally, in a lawful
              manner that is compatible with the context in which you provided
              the information.
            </li>
          </Typography>
          <Typography component="h6" variant="h6" gutterBottom>
            Children Under Thirteen
          </Typography>
          <Typography gutterBottom component="p" variant="p">
            Hypernova Technologies does not knowingly collect personally
            identifiable information from children under the age of thirteen. If
            you are under the age of thirteen, you must ask your parent or
            guardian for permission to use this website.
          </Typography>
          <Typography component="h6" variant="h6" gutterBottom>
            E-mail Communications
          </Typography>
          <Typography gutterBottom component="p" variant="p">
            From time to time, Hypernova Technologies may contact you via email
            for the purpose of providing announcements, promotional offers,
            alerts, confirmations, surveys, and/or other general communication.
            If you would like to stop receiving marketing or promotional
            communications via email from Hypernova Technologies, you may opt
            out of such communications by replying STOP.
          </Typography>
          <Typography component="h6" variant="h6" gutterBottom>
            External Data Storage Sites
          </Typography>
          <Typography gutterBottom component="p" variant="p">
            We may store your data on servers provided by third party hosting
            vendors with whom we have contracted.
          </Typography>
          <Typography component="h6" variant="h6" gutterBottom>
            Changes to this Statement
          </Typography>
          <Typography gutterBottom component="p" variant="p">
            Hypernova Technologies reserves the right to change this Privacy
            Policy from time to time. We will notify you about significant
            changes in the way we treat personal information by sending a notice
            to the primary email address specified in your account, by placing a
            prominent notice on our website, and/or by updating any privacy
            information. Your continued use of the website and/or Services
            available after such modifications will constitute your: (a)
            acknowledgment of the modified Privacy Policy; and (b) agreement to
            abide and be bound by that Policy.
          </Typography>
          <Typography component="h6" variant="h6" gutterBottom>
            Contact Information
          </Typography>
          <Typography gutterBottom component="p" variant="p">
            Hypernova Technologies welcomes your questions or comments regarding
            this Statement of Privacy. If you believe that Hypernova
            Technologies has not adhered to this Statement, please contact
            Hypernova Technologies at: info@hypernovadev.space
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </BootstrapDialog>

    </ThemeProvider>

  );
};

export default SignIn;