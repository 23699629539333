import React, { useState, useContext, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import Firebase from "firebase/compat/app";
import { FirebaseAuth } from "../components/FirebaseAuth/firebase";
import { db } from "../components/FirebaseAuth/firebase";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import background from "../assets/underground.jpg";
import Grid from "@mui/material/Grid";
import { Avatar } from "@mui/material";

// const BackgroundtoGreen = async () => {
//     const db = Firebase.firestore();
//     const docRef = doc(db, "accounts", "dVbxl5iYEk7a6KO3nkWB");
//     const docSnap = await getDoc(docRef);
//     let displayn = docSnap.data().tagline;

//     if (docSnap.exists()) {
//         console.log("Document data:", displayn);
//       } else {
//         // doc.data() will be undefined in this case
//         console.log("No such document!");
//       }
//     // docRef.toString();
//     // console.log(docSnap.data().displayName)

//   }

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const bull = (
  <Box
    component="span"
    sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
  >
    •
  </Box>
);

// BackgroundtoGreen();

function Base() {
  const db = Firebase.firestore();
  const { accountId } = useParams();
  const [name, setName] = useState("");
  const [dob, setDob] = useState("");
  const [label, setLabel] = useState("");
  const [monday, setMonday] = useState("--");
  const [bio, setBio] = useState("");
  const [instagram, setInstagram] = useState("");
  const [twitter, setTwitter] = useState("");
  const [youtube, setYoutube] = useState("");
  const [accountImage, setAccountImage] = useState({ background });
  const [musicTrack1, setMusicTrack1] = useState("");
  const [type, setType] = useState("");

  const [credits, setCredits] = useState(0);
  var docRef = db.collection("accounts").doc(accountId);
  // var DocRef = Firestore.doc('/accounts/'+userData.currentAccount.id+'/credits/'+value);
  docRef
    .get()
    .then((doc) => {
      if (doc.exists) {
        setName(doc.data().name);
        setBio(doc.data().bio);
        setLabel(doc.data().label);
        setType(doc.data().type);
        setInstagram(doc.data().instagram);
        setYoutube(doc.data().youtube);
        setTwitter(doc.data().twitter);
        setAccountImage(doc.data().image);
        setDob(doc.data().dob);
        // console.log("Document data:", doc.data().credits);
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
    })
    .catch((error) => {
      console.log("Error getting document:", error);
    });
  // db.collection("info").where("id", "==", "1")
  //     .get()
  //     .then((querySnapshot) => {
  //         querySnapshot.forEach((doc) => {
  //             // doc.data() is never undefined for query doc snapshots
  //             console.log(doc.id, " => ", doc.data().tagline);
  //         });
  //     })
  //     .catch((error) => {
  //         console.log("Error getting documents: ", error);
  //     });

  return (
    <div style={{ margin: "5%", backgroundColor: "#000000", color: "white" }}>
      <Box sx={{ minWidth: 275 }}>
        <Card
          variant="outlined"
          elevation={6}
          style={{
            backgroundColor: "black",
            border: "1px solid white",
            color: "white",
          }}
        >
          <Grid container style={{ textAlign: "center" }}>
            <Grid item sm={4}>
              {/* <img src={accountImage} style={{width: '50%', height: 'auto'}} /> */}
              <div style={{ margin: "3%" }}>
                <Avatar
                  src={accountImage}
                  style={{ width: "100%", height: "auto" }}
                />
              </div>

              <Typography variant="h3" component="div">
                {name}
              </Typography>
            </Grid>
            <Grid item sm={8}>
              <Table style={{ margin: "3%", maxWidth: '100%' }}>
                <TableRow>
                  <TableCell><h1>Name: {name}</h1></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Date of Birth: {dob}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Bio: {bio}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Label: {label}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Account Type: {type}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    {" "}
                    <Stack
                      direction="row"
                      divider={<Divider orientation="vertical" flexItem />}
                      spacing={2}
                    >
                      <a style={{ color: "white" }}>Socials:</a>
                      <a
                        href={`https://instagram.com/${instagram}`}
                        style={{ color: "red" }}
                      >
                        <InstagramIcon />
                      </a>
                      <a href={twitter} style={{ color: "red" }}>
                        <TwitterIcon />
                      </a>
                      <a href={youtube} style={{ color: "red" }}>
                        <YouTubeIcon />
                      </a>
                    </Stack>
                  </TableCell>
                </TableRow>
              </Table>
            </Grid>
          </Grid>

          {/* <Stack direction="row" style={{margin: '3%'}}>
              <Typography sx={{ mb: 1.5 }} color="text.secondary" style={{marginRight: '4px'}}>
                <img
                  src={accountImage1}
                  style={{ maxWidth: "300px", maxHeight: "300px" }}
                />
              </Typography>
              <Typography sx={{ mb: 1.5 }} color="text.secondary" style={{marginRight: '4px'}}>
                <img
                  src={accountImage2}
                  style={{ maxWidth: "300px", maxHeight: "300px" }}
                />
              </Typography>
              <Typography sx={{ mb: 1.5 }} color="text.secondary" style={{marginRight: '4px'}}>
                <img
                  src={accountImage3}
                  style={{ maxWidth: "300px", maxHeight: "300px" }}
                />
              </Typography>
              <Typography sx={{ mb: 1.5 }} color="text.secondary" style={{marginRight: '4px'}}>
                <img
                  src={accountImage4}
                  style={{ maxWidth: "300px", maxHeight: "300px" }}
                />
              </Typography>
            </Stack> */}
        </Card>
      </Box>
      {/* This is a base component
      <br />
      {accountId}
      and 
     {tagline}
     and 
     {name} */}
      {/* <button onClick={backgroundtoGreen()}>test</button> */}
    </div>
  );
}

export default Base;
