import React, { useState, useContext, useEffect, useRef } from "react";
import { CloudFunctions } from "../../../../components/FirebaseAuth/firebase";
import { BreadcrumbContext } from "../../../../components/Breadcrumb";
import { Form, Input } from "../../../../components/Form";
import { Redirect } from "react-router-dom";
import { Container, Paper, Box, Alert } from "@mui/material";
import firebase from "firebase/compat/app";
import '@firebase/storage';
import { ref, getDownloadURL, uploadBytesResumable, uploadBytes } from "firebase/storage";
import { v4 } from "uuid";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';



const NewAccount = () => {
    const storage = firebase.storage();
    const [imageUpload, setImageUpload] = useState(null);
  const [imageUrls, setImageUrls] = useState([]);
  // const [age, setAge] = React.useState('');

  // const handleChange = (event) => {
  //   setAge(event.target.value);
  // };


  const imagesListRef = ref(storage, "images/");
  const uploadFile = () => {
    if (imageUpload == null) return;
    const imageRef = ref(Storage, `images/${imageUpload.name}`);
    uploadBytes(imageRef, imageUpload).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        setImageUrls((prev) => [...prev, url]);
      });
    });
  };

  const title = "Create New Account";
  const mountedRef = useRef(true);

  const [accountName, setAccountName] = useState({
    hasError: false,
    error: null,
    value: null,
  });

  const [accountType, setAccountType] = useState({
    hasError: false,
    error: null,
    value: null,
  });

  const [accountDob, setAccountDob] = useState({
    hasError: false,
    error: null,
    value: null,
  });

  const [accountBio, setAccountBio] = useState({
    hasError: false,
    error: null,
    value: null,
  });

  const [accountLabel, setAccountLabel] = useState({
    hasError: false,
    error: null,
    value: null,
  });

  const [accountGenres, setAccountGenres] = useState({
    hasError: false,
    error: null,
    value: null,
  });

  const [accountBc, setAccountBc] = useState({
    hasError: false,
    error: null,
    value: null,
  });

  const [accountLongitude, setAccountLongitude] = useState({
    hasError: false,
    error: null,
    value: null,
  });

  const [accountLatitude, setAccountLatitude] = useState({
    hasError: false,
    error: null,
    value: null,
  });

  const [accountBooking, setAccountBooking] = useState({
    hasError: false,
    error: null,
    value: null,
  });

  const [accountYoutube, setAccountYoutube] = useState({
    hasError: false,
    error: null,
    value: null,
  });

  const [accountTwitter, setAccountTwitter] = useState({
    hasError: false,
    error: null,
    value: null,
  });

  const [accountInstagram, setAccountInstagram] = useState({
    hasError: false,
    error: null,
    value: null,
  });

  const [accountImage, setAccountImage] = useState({
    hasError: false,
    error: null,
    value: null,
  });

 


  const [errorMessage, setErrorMessage] = useState(null);

  const [inSubmit, setInSubmit] = useState(false);

  const [redirect, setRedirect] = useState(null);
  const { setBreadcrumb } = useContext(BreadcrumbContext);

  useEffect(() => {
    setBreadcrumb([
      {
        to: "/",
        text: "Home",
        active: false,
      },
      {
        to: null,
        text: title,
        active: true,
      },
    ]);
  }, [setBreadcrumb, title]);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  return (
    <Container>
      <Paper>
        <Box p={2}>
          {redirect === null && (
            <>
              {errorMessage !== null && (
                <Alert
                  severity="error"
                  dismissible="true"
                  onDismiss={() => setErrorMessage(null)}
                >
                  {errorMessage}
                </Alert>
              )}
              <div className="card-body">
                <Form
                  handleSubmit={(e) => {
                    e.preventDefault();
                    setInSubmit(true);
                    setErrorMessage(null);
                    const createAccount =
                      CloudFunctions.httpsCallable("createAccount");
                    createAccount({
                      accountName: accountName.value,
                      accountImage: accountImage.value,
                      accountType: accountType.value,
                      accountDob: accountDob.value,
                      accountBio: accountBio.value,
                      accountLabel: accountLabel.value,
                      accountGenres: accountGenres.value,
                      accountBc: accountBc.value,
                      accountBooking: accountBooking.value,
                      accountYoutube: accountYoutube.value,
                      accountTwitter: accountTwitter.value,
                      accountInstagram: accountInstagram.value
                    })
                      .then((response) => {
                        if (!mountedRef.current) return null;
                        const accountId = response.data.accountId;
                        // const accountName = response.data.accountName;
                        // const accountTagline = response.data.accountTagline;
                        // const accountBio = response.data.accountBio;
                        // const accountWebsite = response.data.accountWebsite;
                        // const accountAddress = response.data.accountAddress;
                        // const accountLongitude = response.data.accountLongitude;
                        // const accountLatitude = response.data.accountLatitude;
                        // const accountMondays = response.data.accountMondays;
                        // const accountTuesdays = response.data.accountTuesdays;
                        // const accountWednesdays = response.data.accountWednesdays;
                        // const accountThursdays = response.data.accountThursdays;
                        // const accountFridays = response.data.accountFridays;
                        // const accountSaturdays = response.data.accountSaturdays;
                        // const accountSundays = response.data.accountSundays;
                        // const accountYoutube = response.data.accountYoutube;
                        // const accountCalendar = response.data.accountCalendar;
                        setRedirect("/account/" + accountId + "/billing/plan");
                      })
                      .catch((err) => {
                        if (!mountedRef.current) return null;
                        setErrorMessage(err.message);
                        setInSubmit(false);
                      });
                  }}
                  disabled={
                    accountName.hasError ||
                    accountName.value === null ||
                    inSubmit
                  }
                  inSubmit={inSubmit}
                  enableDefaultButtons={true}
                >
                  <Input
                    label="Name"
                    type="text"
                    name="account-name"
                    maxLen={100}
                    required={true}
                    changeHandler={setAccountName}
                    fullWidth
                    variant="outlined"
                  />
                  {/* <InputLabel >Profile Image URL</InputLabel> */}
                  <Input
                    label="Profile Image URL"
                    type="text"
                    name="account-image"
                    required={true}
                    changeHandler={setAccountImage}
                    fullWidth
                    variant="outlined"
                  />
                  {/* <InputLabel >Artist</InputLabel> */}
        {/* <Select
                         label="Artist or Producer?"
                         type="text"
                         name="account-type"
                         maxLen={100}
                         required={true}
                         changeHandler={setAccountType}
                         fullWidth
                         variant="outlined"
          onChange={handleChange}
        >
          <MenuItem value={"Artist"}>Artist</MenuItem>
          <MenuItem value={"Producer"}>Producer</MenuItem>
        </Select> */}
                   <Input
                    label="Artist or Producer?"
                    type="text"
                    name="account-type"
                    maxLen={100}
                    required={true}
                    changeHandler={setAccountType}
                    fullWidth
                    variant="outlined"
                  />
                  <Input
                    label="Date of Birth"
                    type="text"
                    name="account-tagline"
                    maxLen={100}
                    required={true}
                    changeHandler={setAccountDob}
                    fullWidth
                    variant="outlined"
                  />
                  <Input
                    label="Biography"
                    type="text"
                    name="account-bio"
                    maxLen={100}
                    required={true}
                    changeHandler={setAccountBio}
                    fullWidth
                    variant="outlined"
                  />
                  <Input
                    label="Current Label"
                    type="text"
                    name="account-label"
                    maxLen={100}
                    required={true}
                    changeHandler={setAccountLabel}
                    fullWidth
                    variant="outlined"
                  />
                  <Input
                    label="Genres"
                    type="text"
                    name="account-genres"
                    maxLen={100}
                    required={true}
                    changeHandler={setAccountGenres}
                    fullWidth
                    variant="outlined"
                  />
                  <Input
                    label="Birth City"
                    type="text"
                    name="account-bc"
                    maxLen={100}
                    required={true}
                    changeHandler={setAccountBc}
                    fullWidth
                    variant="outlined"
                  />
                    <Input
                    label="Booking"
                    type="text"
                    name="account-booking"
                    maxLen={6}
                    required={true}
                    changeHandler={setAccountBooking}
                    fullWidth
                    variant="outlined"
                  />
                
                  <Input
                    label="Youtube URL"
                    type="text"
                    name="account-youtube"
                    maxLen={100}
                    required={true}
                    changeHandler={setAccountYoutube}
                    fullWidth
                    variant="outlined"
                  />
                  <Input
                    label="Twitter URL"
                    type="text"
                    name="account-twitter"
                    maxLen={100}
                    required={true}
                    changeHandler={setAccountTwitter}
                    fullWidth
                    variant="outlined"
                  />
                  <Input
                    label="Instagram Username"
                    type="text"
                    name="account-instagram"
                    maxLen={100}
                    required={true}
                    changeHandler={setAccountInstagram}
                    fullWidth
                    variant="outlined"
                  />
                </Form>
              </div>
            </>
          )}
          {redirect !== null && <Redirect to={redirect}></Redirect>}
        </Box>
      </Paper>
    </Container>
  );
};

export default NewAccount;
