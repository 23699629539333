import React, {useState} from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { getFirestore, doc, getDoc } from "firebase/firestore";
import Firebase from "firebase/compat/app";

function cdraft() {
    // const db = Firebase.firestore();
    // const [ims, SetIMS] = useState("Loading Pic");

    // function search() {
    //     db.collection("accounts")
    //       .get()
    //       .then((querySnapshot) => {
    //         querySnapshot.forEach((doc) => {
    //           // doc.data() is never undefined for query doc snapshots
    //           console.log(doc.id, " => ", doc.data().name);
    //           SetIMS(doc.data().image1);
    //         });
    //       });
    //   }
    return (
        <div>
            {/* <button onclick={search}>Show items</button> */}
            <Carousel>
                <div>
                    <img src="" />
                    <p className="legend">Legend 1</p>
                </div>
               
            </Carousel>
        </div>
    )
};

export default cdraft;