import react, { useState, useRef, useEffect, useContext } from "react";
import { BreadcrumbContext } from "../../../../components/Breadcrumb";
import Firebase from "firebase/compat/app";
import { AuthContext } from "../../../../components/FirebaseAuth";
import { collection, doc, getDocs } from "firebase/firestore";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { connectStorageEmulator } from "firebase/storage";

function Collective(props) {
  const [dataState, setDataState] = useState();
  const [prices, setPrices] = useState("Loading Prices");

  var data = [];
  const db = Firebase.firestore();
  useEffect(() => {
    const data = [];
    db.collection("accounts")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          // console.log(doc.id, " => ", doc.data());

          data.push(doc.data());
          // console.log("TESTING DATA", data);
        });
        setDataState(data);
      });
    console.log(data, "this is data");
  }, [true]);
  // console.log(dataState, "datastate.name");

  function search() {
    db.collection("accounts")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots

          data.push({ name: doc.data().name, price: doc.data().price });
        });
        setDataState(data);
      });
    // console.log(data, "data before setdata");
  }

  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }

  return (
    <div>
      Bands and Venues Information
      <br />
      {/* <button onClick={search}>Search for entities and prices</button> */}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Names</TableCell>
              <TableCell align="center">Type</TableCell>
              <TableCell align="center">Price</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataState !== null && dataState !== undefined
              ? dataState.map((d) => {
                  return (
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="center">{d?.name}</TableCell>
                      <TableCell align="center">{d?.type}</TableCell>
                      <TableCell align="center">{d?.price}</TableCell>
                    </TableRow>
                  );
                })
              : "No Data Found"}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default Collective;
