import React, { useEffect, useState } from "react";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import {
  FirebaseAuth,
  CloudFunctions,
} from "../../../components/FirebaseAuth/firebase";
import Firebase from "firebase/compat/app";
import Loader from "../../../components/Loader";
const SearchBox = () => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState(null);

  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    console.log(string, results);
  };

  const handleOnHover = (result) => {
    // the item hovered
    console.log(result);
  };

  const handleOnSelect = (item) => {
    // the item selected
    // setRedirect("/profile/" + item.sid);
    window.location.href=`/profile/${item.sid}`
    console.log(item.sid);
  };

  const handleOnFocus = () => {
    console.log("Focused");
  };
  const getUsers = () => {
    setLoading(true);
    const db = Firebase.firestore();
    var docRef = db.collection("accounts");
    docRef
      .get()
      .then((doc) => {
        let arr = [];
        let i = 0;
        doc.forEach((documents) => {
          i = i + 1;
          arr.push({
            id: i,
            name: documents.data().name,
            sid: documents.id
          });
        });

        setItems(arr);
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  };
  useEffect(() => {
    getUsers();
  }, []);
  return (
    <div style={{ marginLeft: "4%", marginRight: "4%", marginTop: "2%" }}>
      {loading ? (
        <div
          style={{
            textAlign: "center",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          <Loader />
        </div>
      ) : (
        <ReactSearchAutocomplete
          items={items}
          styling={{backgroundColor: 'black', border: '1px solid #d4ae36', color: '#d4ae36'}}
          showClear
          placeholder="Search user"
          onSearch={handleOnSearch}
          onHover={handleOnHover}
          onSelect={handleOnSelect}
          onFocus={handleOnFocus}
          autoFocus
        />
      )}
    </div>
  );
};
export default SearchBox;